<template>
    <section>
        <modal ref="modalEntrega" :titulo="nombreEntrega" tamano="modal-lg" :no-cancelar="true" :no-aceptar="true" :cerrar="true">
            <div class="row mx-0">
                <div class="col-auto d-middle">
                    <div class="bg-white text-center px-4 br-12 shadow border-cant">
                        <p class="text-gris f-600"> Calificación </p>
                        <p class="text-general f-600 f-40"> {{ notas.califica_nota }} </p>
                    </div>
                </div>
                <div class="col">
                    {{ notas.califica_comentario }}
                </div>
            </div>
            <div class="row mx-0 border-bottom mt-3 py-3 a-center px-4">
                <div class="col-auto px-0">
                    <img :src="notas.fotoUserCalifico" width="55" height="55" class="obj-cover rounded-circle border" />
                </div>
                <div class="col text-gris">
                    {{ notas.nombreUserCalifico }}
                </div>
                <div class="col-3">
                    {{ formatearFecha(notas.califica_fecha, 'DD / MMM / YYYY') }}
                </div>
                <div class="col-auto">
                    {{ formatearFecha(notas.califica_fecha, 'hh:mm A') }}
                </div>
            </div>
            
            <div class="row mx-0  py-3 a-center px-4">
                <div class="col-auto px-0">
                    <img :src="data.fotoUser" width="55" height="55" class="obj-cover rounded-circle border" />
                </div>
                <div class="col text-gris">
                    {{ data.nombreUser }}
                </div>
                <div class="col-3">
                    {{ formatearFecha(data.created_at, 'DD / MMM / YYYY') }}
                </div>
                <div class="col-auto">
                    {{ formatearFecha(data.created_at, 'hh:mm A') }}
                </div>
                <div class="col-12 px-0">
                    <div class="row mx-0">
                        <div class="col-auto" />
                        <div class="col-auto" />
                        <div class="col">
                            <p>{{ notas.user_comentario }}</p>
                            <!-- <div v-for="(data, d) in adjuntos" :key="d" class="row mx-0 my-2">
                                <div class="col-auto px-1 cr-pointer">
                                    <i class="f-18 text-general" :class="icon_extension(data.archivo)" />
                                </div>
                                <div class="col-6 tres-puntos">
                                    {{ data.nombre }}
                                </div>
                            </div>                         -->
                        </div>
                        <div class="col-auto" />
                        <div class="col-auto" />
                    </div>
                </div>
            </div>
        </modal>
        <!-- <visor-pdf ref="vPdf" /> -->
        <modal-video ref="modal_video" />
        <modal-imagen ref="ver_archivo" />
    </section>
</template>

<script>
import Curso from "../../../services/cursos/ver_cursos";


export default {
    props: {
        nombreEntrega: {
            type: String,
            default: '',
        },
    },
    data(){
        return {
            entregaHecha: [],
            data: {},
            notas: {},
            
        }
    },
    methods: {
        toggle(entrega){
            console.log(entrega);
            this.data = entrega
            this.notas = this.data.hecha
            this.getEntregasAdjuntos(this.notas.id_entrega)
            this.$refs.modalEntrega.toggle()
            
        },
        async getEntregasAdjuntos(idEntregaHecha){
            try {
                const { data: { data } } = await Curso.getEntregasAdjuntos(idEntregaHecha)
                this.entregaHecha = data.entregaHecha.adjuntos
                console.log(this.entregaHecha)

            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.border-cant{
    border: 1px solid #f8f8f8;
}
</style>